<template>
  <section>
    <div class="container">
      <div class="row">

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SomeName',
  props: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
